export enum Keys {
  TradeList_Type = "tradelist-type",
  TradeList_Good = "tradelist-good",
  Credits = "credits",
  Player = "player",
  Automation = "automation",
  Debug = "debug",
  ShipUpgrade = "ship-upgrade",
  Theme = "theme",
  Locations = "locations",
  AvailableStructures = "available-structures",
  Markets_Location = "markets-location",
  Markets_Good = "markets-good",
  FuelCalc_From = "fuelcalc-from",
  FuelCalc_To = "fuelcalc-to",
  Resetting = "resetting",
  Best_From = "best-from",
  Best_To = "best-to",
  Best_Good = "best-good",
  Best_Ship = "best-ship",
}
