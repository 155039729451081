import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const SpaceshipIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 512 512">
        <path d="M512,435.2a25.6,25.6,0,0,1-25.6,25.6H396.8V235.36a602.12,602.12,0,0,0,51.2,59C512,358.4,512,435.2,512,435.2Zm-512,0a25.6,25.6,0,0,0,25.6,25.6h89.6V235.36a602.15,602.15,0,0,1-51.2,59C0,358.4,0,435.2,0,435.2ZM371.2,134.53V460.8h-32l1,1.45C354.35,483.52,339.1,512,313.55,512H198.45c-25.56,0-40.8-28.48-26.63-49.75l1-1.45h-32V134.53s0-75.46,89-127.45a51.88,51.88,0,0,1,52.34,0C371.2,59.08,371.2,134.53,371.2,134.53ZM318.87,476.45l-8.53-12.8A6.39,6.39,0,0,0,305,460.8H207a6.39,6.39,0,0,0-5.32,2.85l-8.53,12.8a6.4,6.4,0,0,0,5.32,10H313.55a6.4,6.4,0,0,0,5.32-10ZM320,125.94s0-41.9-49.43-70.79a29,29,0,0,0-29.13,0C192,84,192,125.94,192,125.94a14.86,14.86,0,0,0,14.86,14.86h98.28A14.86,14.86,0,0,0,320,125.94ZM257.65,77.25a3.39,3.39,0,0,0-3.3,0C232.8,89.84,223.88,105,220.19,115.2h71.62C288.12,105,279.19,89.84,257.65,77.25Z" />
      </svg>
    </SvgIcon>
  );

  /* <a href='https://dryicons.com/icon/spaceship-icon-5255'> Icon by Dryicons </a> */
};
